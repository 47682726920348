/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { graphql } from 'gatsby';

import { RichText } from 'prismic-reactjs';

import LinkFragment from '../utils/linkFragment';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import PartnerItem from '../components/PartnerItem';

import { breakpoint } from '../theme';

const Container = styled.div`
  text-align: center;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(110)};
`;

const Intro = styled(Col)`
  text-align: center;
  margin: ${rem(50)} auto ${rem(75)};
  width: 100%;
  /* max-width: ${(props) => props.theme.grid.maxWidthText}; */
  max-width: ${rem(500)};
  font-size: ${rem(18)};

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(680)};
    font-size: ${rem(20)};
  }

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
  }
`;

const Section = styled(Row)`
  justify-content: center;
  margin-bottom: ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const GridItem = styled(Col)`
  display: inline-block;
  width: ${rem(185)};

  ${breakpoint('large')} {
    width: ${rem(205)};
  }
`;

const ColumnsTitle = styled.h2`
  display: block;
  width: 100%;
  font-size: ${rem(24)};
  margin-bottom: ${rem(30)};

  ${breakpoint('large')} {
    font-size: ${rem(28)};
    margin-bottom: ${rem(35)};
  }
`;

function App({ data }) {
  const { allPartners: items, partners: page } = data.prismic;

  if (!page) {
    return null;
  }

  // console.log(data);

  const sortedItems = items.edges.length
    ? items.edges.sort((a, b) => {
        const x = a.node.title[0].text.toLowerCase();
        const y = b.node.title[0].text.toLowerCase();

        // eslint-disable-next-line no-nested-ternary
        return x < y ? -1 : x > y ? 1 : 0;
      })
    : [];

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      <Hero heading={RichText.asText(page.title)} image={page.hero_imageSharp}>
        {page.hero_text && <RichText render={page.hero_text} />}
      </Hero>

      {page.content && page.content.length && RichText.asText(page.content) && (
        <Row>
          <Intro>
            <RichText render={page.content} />
          </Intro>
        </Row>
      )}

      <Container>
        <Row>
          <Col>
            <ColumnsTitle>Our friends</ColumnsTitle>
          </Col>
        </Row>
        <Section>
          {sortedItems.map(({ node: project }) => (
            <GridItem key={project._meta.id}>
              <PartnerItem data={project} />
            </GridItem>
          ))}
        </Section>
      </Container>
    </Layout>
  );
}

App.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query PartnersQuery {
    prismic {
      partners(lang: "en-gb", uid: "friends") {
        content
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_text
        seo_description
        seo_meta_title
        title
      }
      allPartners(sortBy: title_ASC) {
        edges {
          node {
            _linkType
            _meta {
              id
              lang
              lastPublicationDate
              tags
              type
              uid
            }
            content
            hero_text
            link {
              ...LinkFragment
            }
            partner_logo
            seo_description
            seo_meta_title
            title
          }
        }
        totalCount
      }
    }
  }
`;

App.fragments = [LinkFragment];

export default App;
